<template>
    <div id="content" class="product-availability-management">
        <div class="bar-actions">
            <Button bType="cancel" v-if="!uniqueSite" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveProductAvailability" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <!-- <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="deleteProductAvailability" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div> -->
        </div>
        <div class="form-container" v-if="productAvailabilityRender && categories && products">
            <div class="form">
                <div class="label">{{ $t('product.product_availability.availability_store', { s: site.name }) }}</div>
                <div class="description">{{ $t('product.product_availability.availability_store_description') }}</div>
                <div class="products">
                    <div class="searcher">
                        <Input v-model="searcher" :gPlaceholder="$t('product.product_availability.search_placeholder')" :gLabel="$t('product.product_availability.search_product')" gSize="ml"></Input>
                    </div>
                    <div class="multiple-select">
                        <div class="select" @click="toggleSelectAll()">
                            <div class="check" :class="activeCount == Object.keys(products).length ? 'inactive' : 'active'"></div>
                        </div>
                        <div class="name" @click="toggleSelectAll()">
                            <template v-if="activeCount == Object.keys(products).length">{{ $t('product.product_availability.deselect_product') }}</template>
                            <template v-else>{{ $t('product.product_availability.select_product') }}</template>
                        </div>
                        <div class="counter">{{ activeCount }} {{ $tc('product.product_availability.counter_selected', activeCount) }}</div>
                    </div>

                    <template v-for="product in productAvailabilityRender">
                        <div class="product" :key="product.id" @click="toggleProduct(product.id)">
                            <div class="select">
                                <div class="check" :class="product.active ? 'active' : 'inactive'"></div>
                            </div>
                            <div class="meta" :class="product.active ? 'active' : 'inactive'">
                                <div class="image" :class="{ 'no-image': !product.image }" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                                <div class="name">{{ product.name }}</div>
                                <span class="category" v-if="categories">
                                    <span class="ball-color" :style="{ backgroundColor: categories[product.category_id] ? categories[product.category_id].color : '' }"></span>
                                    {{ categories[product.category_id] ? categories[product.category_id].name : '-' }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'ProductAvailabilityManagement',
    props: { id: undefined, uniqueSite: false },
    components: {},
    data() {
        return {
            sendForm: true,
            form: false,
            moreOptions: false,
            idSite: false,
            searcher: '',
            activeCount: 0,
            allSelected: false
        }
    },
    computed: {
        products: {
            get() {
                var products = this.$store.getters['label/getProductAvailability']
                this.activeCount = Object.values(products).reduce((counter, { active }) => (active == 1 ? (counter += 1) : counter), 0) // 6
                return this.$store.getters['label/getProductAvailability']
            },
            set(value) {
                this.$store.commit('label/updateProductAvailability', value)
            }
        },

        productAvailabilityRender() {
            if (this.products && this.searcher && this.searcher != '') {
                return Object.values(this.products).filter((product) => {
                    return product.name.toLowerCase().match(this.searcher.toLowerCase()) || this.categories[product.category_id].name.toLowerCase().match(this.searcher.toLowerCase())
                })

                // var searcher = this.searcher
                // console.log(this.searcher)
                // console.log(Object.values(this.products))
                // console.log(Object.values(this.products).filter(o => Object.keys(o).some(k => o[k].toLowerCase().includes(searcher.toLowerCase()))))
                // return Object.values(this.products).filter(o => Object.keys(o).some(k => o[k].toLowerCase().includes(searcher.toLowerCase())))
            } else {
                return Object.values(this.products).sort(function (a, b) {
                    var nameA = a.name.toUpperCase()
                    var nameB = b.name.toUpperCase()
                    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
                })
            }
        },

        site() {
            //return this.$store.getters['location/getListTable'](this.id)
            return this.$store.getters['label/getProductAvailabilitySiteList'](this.id)
        },

        categories() {
            return this.$store.getters['label/getCategories']()
        }
    },
    methods: {
        toggleSelectAll() {
            var setActive = 1
            if (this.activeCount == Object.keys(this.products).length) {
                setActive = 0
            }
            this.products = Object.values(this.products).forEach((element) => (element.active = setActive))
        },

        toggleProduct(id) {
            this.products[id].active = this.products[id].active == 1 ? 0 : 1
        },

        updateValues(v) {
            this.productAvailabilityRender = this.productAvailabilityRender
        },

        // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
        // showMoreOptions() {
        //     this.moreOptions = !this.moreOptions
        // },

        load() {
            this.$overlay.loading()
            var self = this
            this.$store.dispatch('label/loadProductAvailability', this.id).then(() => {
                self.$store.dispatch('label/loadCategories', {}).then(() => {
                    if (self.$route.params.id) {
                        self.idSite = self.$route.params.id
                    } else {
                        self.idSite = self.id
                        self.$router.push({
                            name: 'EditProductAvailability',
                            params: { id: self.id }
                        })
                    }
                    self.$overlay.hide()
                })
            })
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveProductAvailability() {
            var activeIds = []
            Object.values(this.products).forEach((element) => (element.active == 0 ? activeIds.push(element.id) : true))
            var self = this
            self.$overlay.loading()
            self.$store.dispatch('label/updateProductAvailability', { siteId: self.idSite, activeIds: activeIds.join(',') }).then((response) => {
                self.goBack()
                self.$snackbar.open({
                    message: self.$t('snackbar.edit_success'),
                    customClass: 'success',
                    duration: 5000
                })
            })
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content.product-availability-management {
    .form-container {
        .label {
            @include font-size($size: ml);
            color: $color-black;
            font-family: $text-bold;
            margin-bottom: 5px;
        }

        .products {
            // width: 65%;
            width: 80%;
            margin: 3em auto;

            .searcher {
                margin: 30px auto 0;
            }

            .multiple-select {
                @include display-flex();
                @include align-items();
                position: relative;

                .select {
                    @include display-flex();
                    @include align-items();
                    width: 30px;
                    height: 50px;

                    .check {
                        border-radius: 100%;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;

                        &.active {
                            @include background($image: img('checked_second.svg') !important);
                        }

                        &.inactive {
                            @include background($image: img('checked_aaaaaa.svg') !important);
                        }
                    }
                }

                .name,
                .counter {
                    @include font-size($size: m);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                    cursor: pointer;
                }

                .counter {
                    position: absolute;
                    right: 0px;
                    cursor: default;
                }
            }

            .product {
                @include display-flex();
                @include align-items();
                height: 55px;
                margin: 15px auto;

                .select {
                    @include display-flex();
                    @include align-items();
                    width: 30px;
                    height: 50px;

                    .check {
                        border-radius: 100%;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;

                        &.active {
                            @include background($image: img('checked_second.svg') !important);
                        }

                        &.inactive {
                            border: 2px solid $color-neutral-300;
                        }
                    }
                }

                .meta {
                    @include border-radius(3px);
                    @include display-flex();
                    @include align-items();
                    width: calc(100% - 30px);
                    background-color: #f8f8f8;
                    height: 55px;
                    padding: 0 15px;
                    cursor: pointer;
                    position: relative;

                    &.inactive {
                        background-color: #f1f1f1;
                    }

                    .image {
                        height: 40px;
                        width: 40px;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        margin-right: 15px;

                        &.no-image {
                            @include background($image: img('image_neutro.svg') !important);
                        }
                    }

                    .name {
                        @include font-size($size: m);
                        color: $color-black;
                        font-family: $text-bold;
                    }

                    .category {
                        @include background($color: $color-neutral-200);
                        // margin: 6px 0;
                        min-height: 2rem;
                        height: auto;
                        width: auto;
                        text-align: center;
                        border-radius: 3px;
                        padding: 6px 9px;
                        font-family: $text-bold;
                        position: absolute;
                        right: 15px;

                        .ball-color {
                            display: inline-block;
                            width: 9px;
                            height: 9px;
                            border-radius: 100%;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }
    .left-container {
        padding-left: 0;
    }
}
</style>
